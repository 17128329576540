<template>
  <div>
    <v-expansion-panels v-model="panel">
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header class="text-h5 font-weight-light">
          <div><v-icon left class="mb-1">fas fa-filter</v-icon>Filtro</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="form" @submit.prevent="get_areas(filtro)">
            <v-row>
              <v-col cols="5" sm="3" md="2">
                <v-select
                  v-model="filtro.empresa"
                  :items="empresas_select"
                  clearable
                  label="Empresa"
                ></v-select>
              </v-col>
              <v-col cols="7" sm="4" md="3">
                <v-text-field
                  v-model.trim="filtro.nombre"
                  label="Nombre"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="2">
                <v-text-field
                  v-model.trim="filtro.abreviacion"
                  label="Abreviación"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="2">
                <v-switch
                  v-model="filtro.inhabilitado"
                  label="Inhabilitada"
                ></v-switch>
              </v-col>
              <v-col cols="6" sm="1" class="d-flex align-center justify-start">
                <v-btn type="submit" color="info" elevation="3" :loading="load">Buscar<v-icon right small>fas fa-search</v-icon></v-btn>
                <div class="ml-6"><LimpiarFiltro /></div>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      :headers="headers"
      :items="areas"
      class="elevation-1 mt-6"
      :loading="load"
    >
      <template v-slot:top>
        <div class="d-flex py-2 px-3">
          <v-spacer></v-spacer>
          <v-dialog
            persistent
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex align-start justify-end mt-n7">
                <v-btn
                  color="success"
                  fab
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>fas fa-plus</v-icon>
                </v-btn>
              </div>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-form ref="form">
                  <v-text-field
                    v-model.trim="editedItem.nombre"
                    :rules="[rules.required]"
                    label="Nombre"
                    validate-on-blur
                  ></v-text-field>

                  <v-row :no-gutters="$vuetify.breakpoint.xs">
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model.trim="editedItem.abreviacion"
                        :rules="[rules.required]"
                        label="Abreviación"
                        validate-on-blur
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                       <v-select
                        v-model="editedItem.empresa"
                        :rules="[rules.required]"
                        :items="empresas_select"
                        label="Empresa"
                        validate-on-blur
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-switch
                    v-model="editedItem.inhabilitado"
                    label="Inhabilitada"
                  ></v-switch>
                </v-form>
              </v-card-text>

              <v-card-actions class="d-flex justify-end pb-4">
                <v-btn
                  color="error"
                  class="mr-4"
                  :disabled="load"
                  @click="dialog = false"
                >
                  Cancelar
                </v-btn>
                <Confirmar :loading="load" @action="save"/>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          color="primary"
          @click="editItem(item)"
        >
          fas fa-pen
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-alert
          class="mx-auto my-3"
          max-width="400"
          border="left"
          dense
          text
          type="warning"
        >
          No hay datos para los filtros seleccionados
        </v-alert>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { db } from '../../firebase'
import { mapState } from 'vuex'
import LimpiarFiltro from '../../components/utils/LimpiarFiltro'
import Confirmar from '../../components/utils/Confirmar.vue'

export default {
  data() {
    return {
      panel: 0,
      dialog: false,
      load: false,
      headers: [
        { text: 'Nombre', value: 'nombre'},
        { text: 'Abreviación', value: 'abreviacion', sortable: false },
        { text: 'Empresa', value: 'empresa_nombre' },
        { text: 'Editar', value: 'actions', align: 'center', sortable: false },
      ],
      areas: [],
      rules: { required: value => !!value || 'Campo requerido' },
      editedIndex: -1,
      editedItem: {
        id: '',
        nombre: '',
        abreviacion: '',
        empresa: '',
        empresa_nombre: '',
        inhabilitado: false,
      },
      defaultItem: {
        id: '',
        nombre: '',
        abreviacion: '',
        empresa: '',
        empresa_nombre: '',
        inhabilitado: false,
      },
    }
  },
  components: {
    LimpiarFiltro, Confirmar
  },
  computed: {
    ...mapState(['filtro', 'empresas_select']),
    formTitle () {
      return this.editedIndex === -1 ? 'Nueva Área' : 'Editar Área'
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  methods: {
    async get_areas(filtro) {
      this.load = true
      this.areas = []

      let response = null
      
      if (filtro.empresa === null || filtro.empresa === '') {
        await db.collection('areas').where('inhabilitado', '==', filtro.inhabilitado).get()
          .then((res) => {
            response = res
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        await db.collection('areas').where('inhabilitado', '==', filtro.inhabilitado).where('empresa', '==', filtro.empresa).get()
          .then((res) => {
            response = res
          })
          .catch((error) => {
            console.log(error)
          })
      }

      response.forEach((doc) => {
        let area = doc.data()
        area.id = doc.id
        const empresa = this.empresas_select.find(emp => emp.value === area.empresa)
        if (empresa) {
          area.empresa_nombre = empresa.text
          this.areas.push(area)
        }
      })

      this.load = false
      this.areas = this.areas.filter(area => this.removeAccents(area.nombre).indexOf(this.removeAccents(filtro.nombre)) >= 0)
      this.areas = this.areas.filter(area => area.abreviacion.toLowerCase().indexOf(filtro.abreviacion.toLowerCase()) >= 0)
      
    },
    async save () {
      if (this.$refs.form.validate()) {

        this.load = true

        if (this.editedIndex > -1) {
          // edicion
          
          await db.collection('areas').doc(this.editedItem.id)
            .update({
              abreviacion: this.editedItem.abreviacion,
              empresa: this.editedItem.empresa,
              inhabilitado: this.editedItem.inhabilitado,
              nombre: this.editedItem.nombre, 
            })
            .then(() => {
              this.editedItem.empresa_nombre = this.empresas_select.find(emp => emp.value === this.editedItem.empresa).text
              Object.assign(this.areas[this.editedIndex], this.editedItem)
            })
            .catch((error) => {
              console.error(error)
            })
            
            
        } else {
          // creacion
          
          await db.collection('areas')
            .add({
              abreviacion: this.editedItem.abreviacion,
              empresa: this.editedItem.empresa,
              inhabilitado: this.editedItem.inhabilitado,
              nombre: this.editedItem.nombre, 
            })
            .then((docRef) => {
              this.editedItem.id = docRef.id
              this.editedItem.empresa_nombre = this.empresas_select.find(emp => emp.value === this.editedItem.empresa).text
              this.areas.push(this.editedItem)
            })
            .catch((error) => {
              console.error(error)
            })
        }

        this.load = false
        this.dialog = false
      }
    },
    editItem (item) {
      this.editedIndex = this.areas.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    close () {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$refs.form.resetValidation()
    },
    removeAccents (str) {
      return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    }
  },
}
</script>